.service-section {
    width: 100%;
    background-color: #f8f8f8;
    /* Couleur de fond de la section */
    padding: 50px 0;
    /* Espacement intérieur de la section */
}

.content-container {
    max-width: 1200px;
    margin: 0 auto;
    /* Centrer le contenu à l'intérieur de la section */
}

.section-title {
    text-align: center;
    color: #0056b3;
    /* Couleur du titre */
    font-size: 2em;
    /* Taille du titre */
}

.section-description {
    text-align: center;
    color: #666;
    /* Couleur de la description */
    font-size: 1.2em;
    /* Taille de la description */
    margin-top: 20px;
    /* Espacement en haut de la description */
}

.en-savoir-plus-button {
    background-color: #0056b3;
    /* Couleur de fond du bouton */
    color: #fff;
    /* Couleur du texte du bouton */
    padding: 10px 20px;
    /* Espacement intérieur du bouton */
    font-size: 16px;
    /* Taille de police du texte */
    border: none;
    /* Suppression de la bordure */
    border-radius: 5px;
    /* Arrondi des coins du bouton */   
    cursor: pointer;
    /* Curseur de type pointer au survol */
    display: block;
    /* Afficher le bouton comme un bloc */
    width: max-content;
    margin-top: 2rem;
    margin: auto;
    /* Centrer horizontalement */
}

.en-savoir-plus-button:hover {
    background-color: #007bff;
    /* Couleur de fond du bouton au survol */
}