.recap-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.recap-table th,
.recap-table td {
    border: 1px solid #dddddd;
    padding: 12px;
    text-align: left;
    width: 50%;
    /* Équilibre de la largeur des colonnes */
}

.recap-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    /* Met en gras le texte dans les en-têtes */
}

/* Ajoute un peu d'espacement entre les cellules */
.recap-table td+td,
.recap-table th+th {
    border-left: 1px solid #dddddd;
}

/* Ajoute une bordure inférieure à la dernière ligne du tableau */
.recap-table tr:last-child td {
    border-bottom: 1px solid #dddddd;
}

/* Ajoute un peu d'ombre pour un effet de levée */
.recap-table td,
.recap-table th {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}

/* Style pour les petits écrans (responsive) */
@media (max-width: 600px) {
    .recap-table {
        display: block;
    }

    .recap-table th,
    .recap-table td {
        width: 100%;
        display: block;
        box-sizing: border-box;
    }
}


@media print {
    .recap-table, h4, a, .alert{
        width: calc(100% - 100px); /* Ajoute des marges de 50px à gauche et à droite */
        margin-left: 50px; /* Marge à gauche */
        margin-right: 50px; /* Marge à droite */
    }

    /* Réinitialise la largeur des cellules pour éviter la déformation */
    .recap-table th, .recap-table td {
        width: auto;
    }
    .print {
        padding-top: 4rem;
    }
}


/* Styles pour le bouton d'impression */
.immigration-form-container .print-btn {
    margin-top: 20px;
}

.invoice-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.company-logo {
    width: 80px;
    /* Ajustez la taille du logo selon vos besoins */
    margin-right: 10px;
}

.company-name {
    font-size: 15px;
    font-weight: bold;
}