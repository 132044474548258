body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Masquer le bouton telecharger */
.rap-button-download {
  display: none !important;
}

.sujet_oral {
  font-size: large;
}


.underline {
  text-decoration: underline;
}


/* Minuteur */

@keyframes blink {

  0%,
  50%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s infinite;
}


#myNav .navbar-toggler-icon {
  color: white;
  background-color: olive;
}

/* Titre du sujet */
.quest_title_container {
  position: relative;
  text-align: center;
  color: white;
}

.quest_title_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customDivider .MuiDivider-root {
  color: #19879e;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
}




.customForm input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  border: 1px solid #dee6f1;
  -webkit-appearance: none;
}

.dropdown-toggle::after {
  color: white;
}

.grille {
  border: 2px solid #323234;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 4px;
  padding-top: 8px;
  padding-bottom: 0px;
}

.grille li {
  margin-bottom: 2px;
}


.aligncenter {
  display: block;
  clear: both;
  margin: 0 auto;
  margin-bottom: 20px;
}

.img_quest {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.my-custom-audio-player {
  background-color: black;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-custom-audio-player audio {
  color: blue;
}

.my-custom-audio-player audio::-webkit-media-controls-panel {
  background-color: black;
  border-radius: 0;
}


.pricingTable {
  color: #fff;
  background: linear-gradient(to right, #3D4353, #2F3545);
  font-family: 'Signika Negative', sans-serif;
  text-align: center;
  padding: 15px 0 22px;
  border: 2px solid #7B8493;
  border-radius: 25px;
}

.pricingTable .pricingTable-header {
  margin: 0 0 20px;
}

.pricingTable .price-value {
  margin: 0 0 15px;
}

.pricingTable .price-value .amount {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  display: block;
}

.pricingTable .price-value .duration {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
}

.pricingTable .title {
  background: linear-gradient(to right, #ED364A, #F35844);
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 0;
  margin: 0;
}

.pricingTable .pricing-content {
  text-align: left;
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
  display: inline-block;
}

.pricingTable .pricing-content li {
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: .5px;
  padding: 0 15px 0 25px;
  margin: 0 0 5px;
  position: relative;
}

.pricingTable .pricing-content li:last-child {
  margin: 0;
}

.pricingTable .pricing-content li:before {
  content: none;
  color: #2a8317;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  position: absolute;
  top: 1px;
  left: 0;
}

.pricingTable .pricing-content li.disable:before {
  content: "\f00d";
  color: #f32e30;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: linear-gradient(to right, #ED364A, #F35844);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 7px 20px 5px;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.3s ease;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.pricingTable.green .title,
.pricingTable.green .pricingTable-signup a {
  background: linear-gradient(to right, #59AB2F, #94D255);
}

.pricingTable.blue .title,
.pricingTable.blue .pricingTable-signup a {
  background: linear-gradient(to right, #5A86E7, #36CEDC);
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 40px;
  }
}



/* Styles for the Privacy page */
.privacy-page {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  /* Couleur du texte */
  margin: 20px;
}

/* Titre de la page Privacy */
.privacy-page h1 {
  font-size: 24px;
  color: #007bff;
  /* Couleur du titre */
  margin-bottom: 10px;
}

.privacy-page h2 {
  font-size: 16px;
  color: #000;
  /* Couleur du titre */
  margin-bottom: 10px;
}



/* Carousel */

.carousel-item {
  width: 25%;
  /* Affiche 4 images côte à côte sur ordinateur */
  float: left;
}

@media (max-width: 768px) {
  .carousel-item {
    width: 50%;
    /* Affiche 2 images côte à côte sur tablette */
  }
}

@media (max-width: 480px) {
  .carousel-item {
    width: 100%;
    /* Affiche 1 image à la fois sur mobile */
  }
}

