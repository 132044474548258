.payment-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.payment-container .title {
  font-size: 1.5rem; /* Taille de police plus grande */
  font-weight: bold; /* Texte en gras */
  color: #0d4f95; /* Couleur bleu vif */
  text-transform: uppercase; /* Texte en majuscules */
  letter-spacing: 2px; /* Espacement entre les lettres */
  margin-bottom: 3.5rem; /* Marge en bas */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Ombre de texte subtile */
}

.payment-container .order-details {
  text-align: left;
  margin-bottom: 2rem;
}

.payment-container .order-details p {
  font-size: 18px;
  margin-bottom: 10px;
}
.payment-container .cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 2.5rem;
}

.cards .card {
  width: 12rem;
  height: 13rem;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, border-color 0.3s ease;
  overflow: hidden;
  padding-bottom: 10PX;
  padding-top: 10px;
}

.payment-container .card:hover {
  transform: translateY(-10px);
  border-color: #007bff;
}

.payment-container .card.active {
  border: 3px solid #20588f; /* Bordure solide de 2px bleu vif */
  box-shadow: 0 0 15px rgba(54, 23, 176, 0.786); /* Ombre légère */
}

.payment-container .card-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.payment-container .card-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.payment-container .card-image {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}

.payment-container .mobile-payment, .operator-logos {
  margin-top: 20px;
}

.payment-container .operator-select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-bottom: 1.7rem;
}

.payment-container .phone-input, .coupon-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1.7rem;
}

.payment-container .net-amount {
  margin-top: 20px;
  text-align: left;
  margin-bottom: 2rem;
}

.payment-container .net-amount p {
  font-size: 18px;
}

.payment-container .input-label {
  font-size: 0.9rem;
  align-items: "left";
  text-align: left;
}

.payment-container .pay-button, .continue-button {
  padding: 14px 28px;
  font-size: 1rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 2rem;
}

.payment-container .note {
  color: #e0212e;
  font-size: 0.6rem;
  padding-top: -15px;
}

.payment-container .pay-button {
  background-color: #28a745; /* Vert */
}

.payment-container .pay-button:hover {
  background-color: #218838; /* Vert foncé */
}

.payment-container .continue-button {
  background-color: #007bff; /* Bleu */
}

.payment-container .continue-button:hover {
  background-color: #0056b3; /* Bleu foncé */
}

.payment-container .net-amount {
  margin-top: 20px;
  text-align: center;
}

.payment-container .net-amount p {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}
