.payment-method-container {
    padding: 20px;
    text-align: center;
}

.payment-method-notice {
    background-color: #e0f4d3;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
}

.payment-method-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    justify-content: center;
    align-items: center;
}

.payment-options-row {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}


.payment-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-card:hover {
    transform: translateY(-5px);
}

.payment-card-image {
    width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: contain;
}

.payment-card-label {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
