.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.payment-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 500px;
    text-align: center;
}

.payment-header {
    margin-bottom: 20px;
}

.payment-image {
    width: 80px;
    height: 80px;
}

.payment-title {
    font-size: 24px;
    font-weight: bold;
    color: #4CAF50;
    margin-top: 10px;
}

.payment-description {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
}

.payment-note {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.contact-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
}

.whatsapp-button {
    background-color: #25D366;
}

.email-button {
    background-color: #4285F4;
}

.contact-icon {
    margin-right: 8px;
}

.payment-date {
    margin-top: 20px;
    font-size: 14px;
    color: #777;
}
