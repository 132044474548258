.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f9fc;
    padding: 20px;
}

.payment-card {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 40px;
    max-width: 800px;
    text-align: center;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.6s ease-in-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.payment-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.payment-image {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 25%;
    background-color: #f0f4f8;
    padding: 10px;
}

.payment-title {
    font-size: 24px;
    font-weight: bold;
    color: #28a745;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

.payment-description {
    font-size: 16px;
    color: #555555;
    margin-bottom: 20px;
    line-height: 1.7;
    font-weight: 500;
}

.payment-note {
    font-size: 14px;
    color: #7d8893;
    margin-bottom: 30px;
}

.whatsapp-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 14px;
    background-color: #25d366;
    color: #ffffff;
    font-size: 14px;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.whatsapp-button:hover {
    background-color: #22bb5b;
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.whatsapp-icon {
    margin-right: 10px;
    font-size: 20px;
}

.subscription-details {
    margin-top: 40px;
    text-align: left;
    overflow-x: scroll;
}

.details-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.details-table {
    width: 100%;
    border-collapse: collapse;
}

.details-table th,
.details-table td {
    padding: 12px 15px;
    border: 1px solid #ccc;
    text-align: left;
    font-size: 14px;
}

.details-table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

.qr-code-section {
    margin: 40px 0;
}

.print-button {
    padding: 5px 12px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.print-button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .payment-card {
        padding: 30px;
        max-width: 100%;
    }

    .payment-image {
        width: 70px;
        height: 70px;
    }

    .payment-title {
        font-size: 22px;
    }

    .payment-description {
        font-size: 15px;
    }

    .details-table th,
    .details-table td {
        font-size: 12px;
    }

    .whatsapp-button {
        padding: 12px 24px;
    }
}